@import url(https://fonts.googleapis.com/css2?family=ABeeZee&family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
	--color-primary: #001f87;
	--color-secondary: #021979;
	--color-red: #d14249;
	--color-light: #44c3e5;
	--color-blu: #0f60b2;
	--border-radius: 15px;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3 {
	font-family: "ABeeZee", sans-serif;
	font-weight: 400;
}

img {
	width: 100%;
}

.container {
	width: 85%;
	margin: 0 auto;
}

@media (max-width: 728px) {
	.container {
		width: 98%;
	}
}

a {
	text-decoration: none;
	color: var(--color-blu);
}

a:hover,
a:focus {
	text-decoration: underline;
	color: var(--color-blu);
}

.sideDrawer {
	height: 100vh;
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 70%;
	max-width: 400px;
	color: black;
	font-weight: bold;
	z-index: 105;
	transform: translateX(-100%);
	transition: transform 0.3s ease;
}

.sideDrawer.open {
	transform: translateX(0);
}

